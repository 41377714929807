/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
import _ from 'lodash'
import React, { useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import {
  cancelRequest,
  useAuth,
  useOrders,
  useCurrencies,
  useUser,
  useMetaDefinitions,
  useSystemSettings,
} from 'react-omnitech-api'
import { useLink, usePriceTemplate, useAlert } from '../../../hook'
import { getOrderLines, getOrderPrices } from '../../../helpers'
import AccountOrderView from './account-order-view'

function AccountOrderController(props) {
  const alert = useAlert()
  const { referenceNumber } = props
  const [loading, setLoading] = useState(false)
  const [order, setOrder] = useState({})

  const PRICE_TEMPLATE_KEY = _.get(usePriceTemplate(), 'code')
  const childViewRef = useRef()

  const {
    fetchOrderByReferenceNumber,
    updateOrder,
  } = useOrders()
  const { auth } = useAuth()
  const { t } = useTranslation()
  const { user } = useUser()
  const { navigate } = useLink()
  const { currencies } = useCurrencies()
  const { metaDefinitions } = useMetaDefinitions()
  const { getSystemSetting } = useSystemSettings()
  const currency = _.find(currencies, { isBaseCurrency: true })

  // local variable
  const seoTitle = t('screens.accountOrder.seo.title', { referenceNumber: order.referenceNumber })
  const taiwanInvoiceEnabled = getSystemSetting('taiwan_invoice.enable')
  const isCancelOrderAvailable = getSystemSetting('order.allow_customer_cancel')

  const onBackToOrders = () => {
    navigate('/account/orders/')
  }

  /**
   * handleCancelOrder
   */
  async function handleCancelOrder() {
    try {
      const includes = [
        'cart_meta',
        'meta',
        'order_lines.price_details',
        'order_lines.sku',
        'order_lines.return_info',
        'order_line_properties',
        'order_line_properties.price_details',
        'order_line_properties.sku',
        'order_line_properties.return_info',
        'order_line_properties.color_option',
        // Roku said to use as temporary solution only for clients
        // without addons as order line property return not supported yet.
        'order_line_properties.related_order_line_id',
        'orders.billing_address',
        'orders.price_details',
        'orders.return_requests',
        'return_requests.meta',
        'return_requests.return_request_lines',
        'return_request_lines.order_line_id',
        'return_request_lines.return_request_reason_id',
        'payment_requests',
        'payment_requests.payment_provider',
        'products.brands',
        'shipments',
        'shipments.courier_service',
        'shipments.delivery_address',
        'shipments.order_lines',
        'shipments.order_line_properties',
        'shipments.store',
        'skus.color_option',
        'skus.product',
        'skus.size_option',
        'stores.address',
      ]
      const params = {
        referenceNumber,
        includes,
        price_template: PRICE_TEMPLATE_KEY,
      }
      const cancelOrderData = await updateOrder({
        byType: 'by_reference_number',
        referenceNumber,
        action: 'cancel',
        params,
      })

      setOrder({
        ...cancelOrderData.order,
        priceDetails: getOrderPrices(cancelOrderData.order),
        shipments: getOrderLines(cancelOrderData.order),
      })
      if (!_.isNull(cancelOrderData)) {
        childViewRef.current.closeModalFallback()
      }
      alert.show(t('screens.accountOrder.cancelOrderMessage'), 'success')
    } catch (error) {
      // suppress errors
      console.error(error)
    }
  }

  /**
   * when the page is loaded
   * without user logged in, redirect to login page
   */
  useEffect(() => {
    if (!auth.userId) {
      navigate(
        '/login/',
        {
          state: {
            redirectUrl: '/account/orders/',
          },
          replace: true,
        },
      )
    }
  }, [])

  useEffect(() => {
    setLoading(true)
    const includes = [
      'cart_meta',
      'meta',
      'order_lines.price_details',
      'order_lines.sku',
      'order_lines.return_info',
      'order_line_properties',
      'order_line_properties.price_details',
      'order_line_properties.sku',
      'order_line_properties.return_info',
      'order_line_properties.color_option',
      // Roku said to use as temporary solution only for clients
      // without addons as order line property return not supported yet.
      'order_line_properties.related_order_line_id',
      'orders.billing_address',
      'orders.cart_meta',
      'orders.price_details',
      'orders.return_requests',
      'return_requests.meta',
      'return_requests.return_request_lines',
      'return_request_lines.order_line_id',
      'return_request_lines.return_request_reason_id',
      'payment_requests',
      'payment_requests.payment_provider',
      'products.brands',
      'products.color_option_variant_type',
      'products.size_option_variant_type',
      'shipments',
      'shipments.courier_service',
      'shipments.delivery_address',
      'shipments.delivery_type',
      'shipments.order_lines',
      'shipments.order_line_properties',
      'shipments.store',
      'skus.color_option',
      'skus.product',
      'skus.size_option',
      'stores.address',
    ]
    const apiParams = {
      referenceNumber,
      includes,
      priceTemplate: PRICE_TEMPLATE_KEY,
    }
    const promise = fetchOrderByReferenceNumber(apiParams)
    promise.then((data) => {
      setOrder({
        ...data.order,
        priceDetails: getOrderPrices(data.order),
        shipments: getOrderLines(data.order),
      })
    }).catch((error) => {
      // TODO: handle error
      console.error('handleGetOrders error: ', error)
    }).finally(() => {
      setLoading(false)
    })

    return function cleanUp() {
      cancelRequest.cancelAll([
        'fetchOrderByReferenceNumber',
      ])
    }
  }, [])

  const viewProps = {
    currency,
    loading,
    metaDefinitions,
    order,
    seoTitle,
    user,
    taiwanInvoiceEnabled,
    isCancelOrderAvailable,
    onBackToOrders,
    onCancelOrder: handleCancelOrder,
  }
  return (
    <AccountOrderView {...viewProps} ref={childViewRef} />
  )
}

export default AccountOrderController
