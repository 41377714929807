import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  listProducts: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginBottom: '1rem',
    padding: '0 2rem',
    '& header': {
      display: 'none',
      height: '3.5rem',
      // '& div': {
      //   heigth: '3rem',
      //   display: 'table-cell',
      //   textAlign: 'center',
      //   textTransform: 'uppercase',
      //   fontSize: '1.4rem',
      //   fontWeight: 600,
      //   '&:first-child': {
      //     width: '54%',
      //   },
      // },
    },
  },
  col: {
    heigth: '3rem',
    display: 'table-cell',
    textAlign: 'center',
    textTransform: 'uppercase',
    fontSize: '1.4rem',
    fontWeight: 600,
  },
  thumbnail: {
    width: '11.6rem',
  },
  name: {
    width: '40%',
  },
  [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
    listProducts: {
      display: 'table',
      borderCollapse: 'collapse',
      marginBottom: '3rem',
      '& header': {
        display: 'table-row',
      },
    },
  },
}))

export default useStyles
