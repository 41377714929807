import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  containerFluid: {
    padding: [[30, 0]],
    width: '100%',
    [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
      padding: [[60, 10]],
    },
  },
  container: {
    textAlign: 'center',
  },
  buttonBack: {
    width: 'auto',
    display: 'flex',
    margin: '0 2rem',
    paddingLeft: '2rem',
    alignItems: 'center',
    '& img': {
      marginLeft: 0,
      left: '0.4rem',
    },
  },
  orderAdditionalInfo: {
    position: 'relative',
    '&::after': {
      content: '""',
      position: 'absolute',
      left: '2rem',
      right: '2rem',
      bottom: 0,
      height: '1px',
      backgroundColor: theme.colors.disabled,
    },
  },
  orderSummaryContainer: {
    textAlign: 'left',
    padding: '0 2rem',
  },
  orderSummaryTitle: {
    fontSize: '1.6rem',
    lineHeight: 1.25,
    marginBottom: '3rem',
    marginTop: '3rem',
    textAlign: 'center',
  },
  buttonBox: {
    display: 'flex',
    flexDirection: 'column',
    margin: '2.2rem auto 0',
    padding: '0 2rem',
    maxWidth: '41rem',
  },
  messageStyle: {
    width: '100%',
    textAlign: 'center',
    fontSize: '1.4rem',
    margin: '3rem auto 0',
    lineHeight: 1.2,
    padding: '0 2rem',
  },
  dialogContainer: {
    maxWidth: '33.8rem',
    margin: '0 auto',
  },
  dialogBody: {
    marginBottom: '3rem',
  },
  dialogTitle: {
    fontSize: '1.8rem',
    fontWeight: 'bold',
    lineHeight: 1.333,
    marginBottom: '0.4rem',
  },
  dialogDescription: {
    fontSize: '1.6rem',
    lineHeight: 1.25,
    marginBottom: '0.4rem',
  },
  button: {
    marginBottom: 30,

  },
}))

export default useStyles
