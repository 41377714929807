// import _ from 'lodash'
import _ from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import useStyles from './account-order-meta-tw-invoice-style'

const AccountOrderMetaTwInvoiceView = ({
  items = [],
}) => {
  const { t } = useTranslation()
  const styles = useStyles()

  if (_.isEmpty(items)) return null

  return (
    <div className={styles.container}>
      <div className={styles.invoiceTo}>
        <h3 className={styles.title}>{t('screens.orderDetail.metaTwInvoice.title')}</h3>
        {
          items.map((item) => (
            <p className={styles.row}>
              <span className={styles.label}>{`${item.label}:`}</span>
              {item.value}
            </p>
          ))
        }
      </div>
    </div>
  )
}

export default AccountOrderMetaTwInvoiceView
