import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  container: () => ({
    position: 'relative',
    marginTop: '2rem',
    marginBottom: '2rem',
    padding: '0 2rem 0.1rem',
    textAlign: 'left',
    [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
      display: 'flex',
    },
  }),
  title: {
    textTransform: 'capitalize',
    fontSize: '3rem',
    marginBottom: '2rem',
  },
  billTo: {
    marginBottom: '3rem',
    width: '100%',
    [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
      width: 'calc(50% - 2rem)',
    },
  },
  name: {
    fontSize: '1.4rem',
    fontWeight: 'bold',
    lineHeight: 1.4,
    margin: 0,
  },
  addressLine: {
    fontSize: '1.4rem',
    lineHeight: 1.4,
    margin: 0,
  },
  phone: {
    fontSize: '1.4rem',
    lineHeight: 1.4,
    margin: 0,
  },
  phoneLabel: {
    fontSize: 'inherit',
    fontWeight: 'bold',
    paddingRight: '0.4rem',
  },
  payments: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '3rem',
    width: '100%',
    [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
      width: 'calc(50% - 2rem)',
    },
  },
  paymentRequest: {
    marginBottom: '2rem',
  },
  paymentDescription: {
    marginTop: 17,
    backgroundColor: '#f4f4f4',
    padding: 20,
    textAlign: 'initial',
    marginBottom: 20,
    '& p, & span': {
      lineHeight: 'initial',
    },
    '& p': {
      marginBottom: 5,
    },
    [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
      marginTop: 10,

    },
  },
  line: {
    marginBottom: '1rem',
    fontSize: '1.4rem',
    lineHeight: 1.143,
    '& span': {
      fontSize: 'inherit',
      lineHeight: 'inherit',
    },
  },
  label: {
    display: 'inline-block',
    margin: 0,
    marginRight: '0.4rem',
  },
  value: {
    display: 'inline-block',
    margin: 0,
  },
  dialogContainer: {
    maxWidth: '57rem',
    margin: '0 auto',
    padding: '0.8rem 0',
    [`@media (min-width: ${theme.mediaQueries.tablet}px)`]: {
      padding: '0 1.6rem',
    },
  },
  dialogBody: {
    marginBottom: '3rem',
  },
  dialogTitle: {
    fontSize: '1.8rem',
    fontWeight: 'bold',
    lineHeight: 1.333,
    marginBottom: '3rem',
  },
  dialogButtons: {
    maxWidth: '33.8rem',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: '-1.6rem',
  },
  button: {
    marginBottom: '1.6rem',
  },

}))

export default useStyles
