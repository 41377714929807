import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  details: {
    padding: '0 2rem',
    textAlign: 'left',
    '& p, & span': {
      fontSize: '1.4rem',
      lineHeight: 1.7,
      margin: 0,
      textTransform: 'uppercase',
    },
    '& p > span:first-child': {
      fontWeight: 600,
      marginRight: '0.5rem',
    },
  },
  [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
    details: {

    },

  },
}))

export default useStyles
