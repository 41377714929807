import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  deliveryStyle: () => ({
    display: 'flex',
    flexDirection: 'column',
    marginTop: '2rem',
    // paddingBottom: (props.billing) ? '2.5rem' : '0',
    // marginBottom: (props.billing) ? '3rem' : '2rem',
    // borderBottom: (props.billing) ? `1px solid ${theme.colors.disabled}` : 'initial',
  }),
  deliveryTitle: {
    minHeight: '4.8rem',
    display: 'flex',
    padding: '1.6rem',
    marginBottom: '2.4rem',
    backgroundColor: theme.colors.bgLight,
    '& p': {
      fontWeight: '600',
      lineHeight: 1.25,
      textAlign: 'left',
      textTransform: 'uppercase',
      margin: 0,
      '& span': {
        marginLeft: '1.5rem',
      },
    },
  },
  confirm: {
    color: theme.colors.confirm,
  },
  shipped: {
    color: theme.colors.active,
  },
  cancel: {
    color: theme.colors.alert,
  },
  bold: {
    fontWeight: '600 !important',
  },
  shipping: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    textAlign: 'left',
  },
  details: {
    padding: '2rem',
    '& h3': {
      textTransform: 'capitalize',
      fontSize: '3rem',
      marginBottom: '2rem',
    },
    '& p': {
      margin: 0,
      fontSize: '1.4rem',
      lineHeight: 1.4,
      '& span': {
        margin: 0,
        lineHeight: 1.4,
        fontSize: '1.4rem',
        '&:nth-child(2)': {
          marginLeft: '1rem',
        },
      },
    },
  },
  info: {
    padding: '2rem',
    '& h3': {
      textTransform: 'capitalize',
      fontSize: '3rem',
      marginBottom: '2rem',
    },
    '& p': {
      margin: 0,
      fontSize: '1.4rem',
      lineHeight: 1.6,
      '& span': {
        margin: 0,
        lineHeight: 1.4,
        fontSize: '1.4rem',
        '&:nth-child(2)': {
          marginLeft: '1rem',
        },
      },
    },
  },
  storePickupQrCode: {
    marginTop: '5rem',
    marginBottom: '5rem',
    paddingLeft: '2rem',
    paddingRight: '2rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  storePickupQrCodeDesc: {
    marginTop: '4rem',
    lineHeight: 1.25,
  },
  [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
    deliveryStyle: () => ({
      marginTop: '6rem',
      marginBottom: '2rem',
    }),
    info: {
      minWidth: '49rem',
    },
    shipping: {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'row',
      textAlign: 'left',
    },

  },
}))

export default useStyles
