import { createUseStyles } from 'react-jss'
import ratioCalc from '../../../../helpers/ratioImage'

const useStyles = createUseStyles((theme) => ({
  row: {
    display: 'block',
    width: '100%',
    background: theme.colors.bgLight,
    border: `1px solid ${theme.colors.bgMedium}`,
    lineHeight: 1,
    padding: '1.6rem',
    marginBottom: '1rem',
    [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
      display: 'table-row',
      padding: 0,
      marginBottom: 0,
    },
  },
  col: {
    display: 'flex',
    justifyContent: 'flex-start',
    fontSize: '1.4rem',
    lineHeight: 1.143,
    fontWeight: 400,
    marginBottom: '0.4rem',
    [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
      display: 'table-cell',
      textAlign: 'center',
      verticalAlign: 'middle',
    },
    '& span': {
      fontSize: 'inherit',
      lineHeight: 'inherit',
    },
  },
  label: {
    textTransform: 'uppercase',
    marginRight: '0.4rem',
  },
  noImage: {
    '& div': {
      background: '#dddddd',
    },
    '& img': {
      width: '50% !important',
    },
  },
  thumbnail: {
    maxWidth: '8rem',
    width: '30%',
    float: 'left',
    marginRight: '2rem',
    minHeight: 145,
    [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
      float: 'none',
      padding: '1.6rem 2rem 1.6rem 1.6rem',
      width: 'auto',
      minheight: 'auto',
    },
  },
  thumbnailImageContainer: {
    padding: 0,
    width: '100%',
    '& div': {
      height: 0,
      padding: 0,
      paddingBottom: ratioCalc(theme.config.ratioThumbnail),
      overflow: 'hidden',
      position: 'relative',
      display: 'block',
      margin: 0,
    },
    '& img': {
      width: '100%',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: ' translate(-50%, -50%)',
    },
  },
  thumbnailImage: {
  },
  name: {
    textAlign: 'left',
    flexDirection: 'column',
    [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
      textAlign: 'left',
    },
  },
  shipmentStyle: {
    textAlign: 'right',
    '& span': {
      lineHeight: '1.4 !important',
      marginBottom: '0.8rem',
    },
  },
  mdHidden: {
    display: 'block',
    [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
      display: 'none !important',
    },
  },
  priceTitle: {
    color: theme.colors.bgMediumDark,
    fontSize: 14,
    lineHeight: '1.8rem !important',
    margin: 0,
  },
  addon: {
    color: theme.colors.bgMediumDark,
    fontSize: 14,
    lineHeight: '1.8rem !important',
    margin: 0,
    '&::before': {
      content: '"- "',
    },
  },
  itemReturnedTag: {
    marginTop: 5,
    '& div': {
      margin: 0,
    },
  },
}))

export default useStyles
