/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-danger */
import _ from 'lodash'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import AccountOrderMetaTwInvoiceView from './account-order-meta-tw-invoice-view'

const AccountOrderMetaTwInvoiceController = ({
  order,
  metaDefinitions,
}) => {
  // prepare hooks
  const { t } = useTranslation()

  // local variables
  const { cartMeta } = order

  const items = useMemo(() => {
    const data = _.compact(_.map([
      'twInvoiceType',
      'twInvoiceDeliveryAddressStr',
      'twInvoiceUniformSn',
      'twInvoiceTitle',
      'twInvoiceDonate',
      'twInvoiceElectronicMobileBarcode',
    ], (item) => {
      // get metaDefinition for this meta
      const definition = _.find(metaDefinitions, ['fieldKey', _.snakeCase(item)])

      // get presentable value of meta. If enum then get name from `metaDefinitionValueListItems`
      let value = _.get(order, `cartMeta.${item}`)
      if (_.isEmpty(value)) return null
      if (_.get(definition, 'fieldType') === 'enum') {
        value = _.get(_.find(_.get(definition, 'metaDefinitionValueListItems'), ['value', value]), 'name') || value
      }
      // get presentable label. Fallback to i18n
      const label = _.get(definition, 'fieldName') || t(`screens.orderDetail.metaInvoice.invoiceOptions.${item}`)
      return {
        label,
        value,
        type: item === 'twInvoiceDeliveryAddressStr' ? 'textarea' : 'text',
      }
    }))
    return data
  }, [cartMeta, metaDefinitions])

  return (
    <AccountOrderMetaTwInvoiceView items={items} order={order} />
  )
}

export default AccountOrderMetaTwInvoiceController
