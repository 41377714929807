import React from 'react'
import { Router } from '@reach/router'
import _ from 'lodash'
import AccountOrderScreen from '../../screens/account/order'

const AccountOrderPage = (props) => (
  <Router>
    <AccountOrderScreen {...props} path={`${_.get(props, 'pageContext.locale')}/account/order/:referenceNumber`} />
  </Router>
)

export default AccountOrderPage
