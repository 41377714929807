import _ from 'lodash'
import React from 'react'
import moment from 'moment'
import QRCode from 'qrcode.react'
import { StateLabel } from '../../../../ui'
import AccountOrderProductTable from '../account-order-product-table'
import useStyles from './account-order-delivery-style'

const AccountOrderDeliveryView = ({
  t, shipment, cartMeta, currency, returnRequestOrderLine,
}) => {
  const {
    deliveryAddress,
    deliveryType,
    courierService,
    state,
    orderLines,
    proposedDeliveryDate,
    proposedDeliveryTime,
    store,
    storePickupQrCodeValue,
  } = shipment
  const styles = useStyles()
  const stateLabel = t('screens.orderDetail.delivery.state', {
    context: _.kebabCase(state),
  })
  const showStore = _.has(store, 'id') && _.get(deliveryType, 'requirePickupStore', false)
  const storePickupTime = _.get(cartMeta, 'takeAwayTime')
  const showQrCode = _.isEqual(state, 'ready_to_receive') && !_.isEmpty(storePickupQrCodeValue)
  const isCatering = !_.get(deliveryType, 'requireDeliveryAddress', false)
    && _.get(deliveryType, 'requireCourierService', false)
    && _.get(deliveryType, 'requirePickupStore', false)
  return (
    <div className={styles.deliveryStyle}>
      <div className={styles.deliveryTitle}>
        <p>
          <StateLabel state={state}>{stateLabel}</StateLabel>
        </p>
      </div>
      <div className={styles.shipping}>
        { !_.isEmpty(_.get(deliveryAddress, 'formattedAddressLines', [])) && (
          <div className={styles.details}>
            <h3>{t('screens.orderDetail.delivery.title')}</h3>
            <p className={styles.bold}>{`${_.get(deliveryAddress, 'firstName')} ${_.get(deliveryAddress, 'lastName')}`}</p>
            {_.get(deliveryAddress, 'formattedAddressLines', []).map((lineAddress) => (
              <p key={lineAddress}>{lineAddress}</p>
            ))}
            <p>
              <span className={styles.bold}>{t('screens.orderDetail.delivery.phone')}</span>
              <span>{_.get(deliveryAddress, 'phoneData.formatted')}</span>
            </p>
          </div>
        )}
        { courierService && (
          <div className={styles.info}>
            <h3>{isCatering ? t('screens.orderDetail.catering.info') : t('screens.orderDetail.delivery.info')}</h3>
            <p>
              <span className={styles.bold}>{t('screens.orderDetail.delivery.service')}</span>
              <span>{_.get(courierService, 'name')}</span>
            </p>
            <p>
              <span className={styles.bold}>{isCatering ? t('screens.orderDetail.catering.date') : t('screens.orderDetail.delivery.date')}</span>
              <span>
                {
                  proposedDeliveryDate
                    ? moment(proposedDeliveryDate).format('YYYY-MM-DD')
                    : t('screens.orderDetail.delivery.notDesignated')
                }
              </span>
            </p>
            <p>
              <span className={styles.bold}>{isCatering ? t('screens.orderDetail.catering.time') : t('screens.orderDetail.delivery.time')}</span>
              <span>
                {
                  proposedDeliveryTime
                    ? `${proposedDeliveryTime.fromTime} - ${proposedDeliveryTime.toTime}`
                    : t('screens.orderDetail.delivery.notDesignated')
                }
              </span>
            </p>
          </div>
        )}
        {
          showStore && (
            <div className={styles.details}>
              <h3>{t('screens.orderDetail.delivery.store')}</h3>
              <p className={styles.bold}>{_.get(store, 'name')}</p>
              {store.address.formattedAddressLines.map((lineAddress) => (
                <p key={lineAddress}>{lineAddress}</p>
              ))}
              <p>
                <span className={styles.bold}>{t('screens.orderDetail.delivery.phone')}</span>
                <span>{_.get(store, 'phoneData.formatted')}</span>
              </p>
              {/* {
                !_.isEmpty(store.description) && (
                  <p
                    dangerouslySetInnerHTML={{
                      __html: _.replace(store.description, /(?:\r\n|\r|\n)+/g, '<br />'),
                    }}
                  />
                )
              } */}
              {
                !_.isEmpty(storePickupTime)
                && moment(storePickupTime).isValid()
                && (
                  <p>
                    <span className={styles.bold}>{t('screens.orderDetail.delivery.storePickupTime')}</span>
                    <span>{moment(storePickupTime).format('h:mm a')}</span>
                  </p>
                )
              }
            </div>
          )
        }
      </div>
      {
        showQrCode && (
          <div className={styles.storePickupQrCode}>
            <QRCode
              value={storePickupQrCodeValue}
              size={168}
            />
            <p className={styles.storePickupQrCodeDesc}>
              {t('screens.orderDetail.delivery.storePickupQrCodeMessage')}
            </p>
          </div>
        )
      }
      <AccountOrderProductTable
        t={t}
        orderLines={orderLines}
        currency={currency}
        returnRequestOrderLine={returnRequestOrderLine}
      />
    </div>
  )
}

export default AccountOrderDeliveryView
