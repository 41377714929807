/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import _ from 'lodash'
import React from 'react'
import { useTheme } from 'react-jss'
import {
  CurrencyFormat, Tag,
} from '../../../../ui'
import placeholderImage from '../../../../assets/images/icon_image_placeholder.png'
import useStyles from './account-order-product-row-style'
import combineClassNames from '../../../../helpers/combineClassNames'

const AccountOrderProductRowView = ({
  orderLine,
  t,
  currency,
  hideColorOptionColumn,
  hideSizeOptionColumn,
  returnRequestOrderLine,
}) => {
  // hook
  const theme = useTheme()
  // CP20210424 - this does not work right now as the ids for `order_line_property` does
  // not match. May need to change later after confirmed with API team how this should be implemented.
  // RJulia 2020/05/13 return items doesn't work by now for FNB when items are in diferent rows
  const isItemReturned = _.map(returnRequestOrderLine, (line) => (line.id === _.get(orderLine, 'relatedOrderLineId') && line.state !== 'cancelled'))[0]

  const {
    addons,
    priceDetails,
    quantity,
    sku,
  } = orderLine
  const placeholder = _.get(theme, 'config.placeholderImage', placeholderImage)
  const thumbnail = _.get(sku, 'colorOption.defaultImage.versions.webMedium', placeholder)
  const noImage = _.isEmpty(_.get(sku, 'colorOption.defaultImage.versions.webMedium'))
  const mainPropertyDiscountGroup = _.get(priceDetails, 'mainPropertyDiscountGroup', [])
  const hideColorOption = _.get(sku, 'product.colorOptionVariantType.hideVariant', false)
  const hideSizeOption = _.get(sku, 'product.sizeOptionVariantType.hideVariant', false)
  const styles = useStyles()
  return (
    <div className={styles.row}>
      <div className={combineClassNames([styles.col, styles.thumbnail])}>
        <div
          className={
            combineClassNames([styles.thumbnailImageContainer, noImage && styles.noImage])
          }
        >
          <div>
            <img
              className={styles.thumbnailImage}
              src={thumbnail}
              alt={_.get(sku, 'product.title')}
            />
          </div>
        </div>
      </div>
      <div className={combineClassNames([styles.col, styles.name])}>
        {_.get(sku, 'product.title')}
        {
          _.map(addons, ({ colorOption: addonColorOption, priceDetails: addonPrice }, idx) => {
            const price = _.get(addonPrice, 'propertyUnitPrice', '0.0')
            return (
              <p key={`addon-${idx}`} className={styles.addon}>
                {_.get(addonColorOption, 'name')}
                {
                  _.toNumber(price) > 0 && (
                    <>
                      {' (+'}
                      <CurrencyFormat currency={currency} value={price} />
                      )
                    </>
                  )
                }
              </p>
            )
          })
        }
        {
          mainPropertyDiscountGroup && _.map(mainPropertyDiscountGroup,
            (price, idx) => (
              <p key={`price-subtotal-${idx}`} className={styles.priceTitle}>
                {_.get(price, 'title')}
              </p>
            ))
        }
        {
          isItemReturned
            && (
            <div className={styles.itemReturnedTag}>
              <Tag text={t('screens.orderDetail.itemReturned')} />

            </div>
            )
        }

      </div>
      {
        !hideSizeOptionColumn && (
          <div className={combineClassNames([styles.col, styles.size])}>
            <span className={combineClassNames([styles.label, styles.mdHidden])}>
              {`${t('screens.orderDetail.detailsProduct.size')}:`}
            </span>
            { !hideSizeOption && (
              <span>{_.get(sku, 'sizeOption.name')}</span>
            )}
          </div>
        )
      }
      {
        !hideColorOptionColumn && (
          <div className={combineClassNames([styles.col, styles.color])}>
            <span className={combineClassNames([styles.label, styles.mdHidden])}>
              {`${t('screens.orderDetail.detailsProduct.color')}:`}
            </span>
            { !hideColorOption && (
              <span>{_.get(sku, 'colorOption.name')}</span>
            )}
          </div>
        )
      }
      <div className={combineClassNames([styles.col, styles.unitPrice])}>
        {/* <span className={combineClassNames([styles.label, styles.mdHidden])}>
          {t('screens.orderDetail.detailsProduct.price')}
          :
        </span> */}
        <CurrencyFormat currency={currency} value={_.get(priceDetails, 'orderLineUnitPrice')} />
      </div>
      <div className={combineClassNames([styles.col, styles.unitPrice])}>
        <span className={combineClassNames([styles.label, styles.mdHidden])}>
          {t('screens.orderDetail.detailsProduct.discount')}
          :
        </span>
        <>
          { mainPropertyDiscountGroup
            && _.map(mainPropertyDiscountGroup,
              (price, idx) => (
                <div>
                  <CurrencyFormat key={`price-breackdown-${idx}`} currency={currency} value={_.get(price, 'totalAmount')} />
                </div>
              ))}
        </>
      </div>
      <div className={combineClassNames([styles.col, styles.quantity])}>
        <span className={combineClassNames([styles.label, styles.mdHidden])}>
          {t('screens.orderDetail.detailsProduct.qty')}
          :
        </span>
        <span>
          {quantity}
        </span>
      </div>
      <div className={combineClassNames([styles.col, styles.subtotal])}>
        <span className={combineClassNames([styles.label, styles.mdHidden])}>
          {t('screens.orderDetail.detailsProduct.subtotal')}
          :
        </span>
        <CurrencyFormat currency={currency} value={_.get(priceDetails, 'orderLineSubtotalPrice')} />
      </div>
    </div>
  )
}

export default AccountOrderProductRowView
