/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-danger */
import _ from 'lodash'
import React, { useState } from 'react'
import QRCode from 'qrcode.react'
import queryString from 'query-string'
import moment from 'moment'
import {
  CurrencyFormat, Button, Modal,
} from '../../../../ui'
import useStyles from './account-order-payments-style'

const AccountOrderPaymentsView = ({
  t = () => {}, order, currency,
}) => {
  const {
    billingAddress,
    paymentRequests,
  } = order
  const styles = useStyles()
  const [isOpenQRCode, setisOpenQRCode] = useState(false)
  const [codeQr, setCodeQR] = useState('')

  function getExtraPaymentInfo(payment, reserved) {
    let extraData = {}
    // TODO: need to generalise this part later
    // special handling for pay2Go orders with offline params (i.e. those orders in reserved status)
    const offlineParamsQueryString = _.get(payment, 'paymentData.newebpayOfflineParams')
      || _.get(payment, 'paymentData.pay2GoOfflineParams', '')
    if (reserved === 'reserved' && !_.isEmpty(offlineParamsQueryString)) {
      const offlineParams = queryString.parse(offlineParamsQueryString)
      const paymentType = _.get(offlineParams, 'PaymentType')
      // fields directly from API
      const paymentTypeFields = {
        VACC: 'PaymentType BankCode CodeNo Amt ExpireDate',
        CVS: 'PaymentType Amt ExpireDate CodeNo',
        BARCODE: 'PaymentType Amt ExpireDate Barcode_1 Barcode_2 Barcode_3',
      }
      let availableFields = []
      // put availabe fields in state for using in render
      if (paymentTypeFields[paymentType]) {
        availableFields = _.map(paymentTypeFields[paymentType].split(' ') || [], (field) => {
          const value = offlineParams[field] || ''
          switch (field) {
            case 'PaymentType':
              return {
                code: field,
                label: `screens.orderDetail.billing.reservedPayment.${payment.provider}.paymentType.${value}`,
                type: 'text',
                value,
              }
            case 'Amt':
              return {
                code: field,
                label: `screens.orderDetail.billing.reservedPayment.${payment.provider}.label.${field}`,
                type: 'text',
                value,
              }
              // using data url to display qrcode image
            case 'CodeNo':
              return {
                code: field,
                label: `screens.orderDetail.billing.reservedPayment.${payment.provider}.label.${field}`,
                type: paymentType === 'CVS' ? 'qrcode' : 'text',
                value,
              }
            default:
              return {
                code: field,
                label: `screens.orderDetail.billing.reservedPayment.${payment.provider}.label.${field}`,
                type: 'text',
                value,
              }
          }
        })
      }
      if (!_.isEmpty(availableFields)) {
        extraData = {
          paymentType,
          availableFields,
        }
      }
    }
    return extraData
  }

  const getCode = (code) => {
    if (!_.isEmpty(code)) {
      const codeQR = _.get(queryString.parse(code), 'CodeNo')
      setCodeQR(codeQR)
    }
  }

  const onOpenQRCode = (code) => {
    setCodeQR('')
    setisOpenQRCode(!isOpenQRCode)
    getCode(code)
  }

  return (
    <div className={styles.container}>
      {
        !_.isEmpty(billingAddress.formattedAddressLines) && (
          <div className={styles.billTo}>
            <h3 className={styles.title}>{t('screens.orderDetail.billing.title')}</h3>
            <p className={styles.name}>{`${_.get(billingAddress, 'firstName')} ${_.get(billingAddress, 'lastName')}`}</p>
            {billingAddress.formattedAddressLines.map((lineAddress, lineAddressIndex) => (
              <p key={`lineAddress__${lineAddressIndex}`} className={styles.addressLine}>{lineAddress}</p>
            ))}
            <p className={styles.phone}>
              <span className={styles.phoneLabel}>{t('screens.orderDetail.billing.phone')}</span>
              {_.get(billingAddress, 'phone')}
            </p>
          </div>
        )
      }
      <div className={styles.payments}>
        { !_.isEmpty(paymentRequests) && <h3 className={styles.title}>{t('screens.orderDetail.billing.info')}</h3> }
        {
          _.map(paymentRequests, (paymentRequest, paymentRequestIndex) => {
            const extradata = getExtraPaymentInfo(paymentRequest, order.state)
            const availableFields = _.get(extradata, 'availableFields')
            return (
              <div key={`paymentRequest__${paymentRequestIndex}`}>
                <div
                  className={styles.paymentRequest}
                >
                  <dl className={styles.line}>
                    <dt className={styles.label}>{`${t('screens.orderDetail.billing.method')}:`}</dt>
                    <dd className={styles.value}>{ paymentRequest.providerComputedName }</dd>
                  </dl>
                  <dl className={styles.line}>
                    <dt className={styles.label}>{`${t('screens.orderDetail.billing.status')}:`}</dt>
                    <dd className={styles.value}>{t('screens.orderDetail.billing.state', { context: _.get(paymentRequest, 'state') })}</dd>
                  </dl>
                  { _.get(paymentRequest, 'authorizedAt')
                && (
                <dl className={styles.line}>
                  <dt className={styles.label}>{`${t('screens.orderDetail.billing.paidOn')}:`}</dt>
                  <dd className={styles.value}>
                    { moment(_.get(paymentRequest, 'authorizedAt')).format('YYYY-MM-DD')}
                  </dd>
                </dl>
                )}
                  <dl className={styles.line}>
                    <dt className={styles.label}>{`${t('screens.orderDetail.billing.amount')}:`}</dt>
                    <dd className={styles.value}>
                      <CurrencyFormat value={_.get(paymentRequest, 'paidAmount')} currency={currency} />
                    </dd>
                  </dl>
                </div>
                {
                  _.map(availableFields || [], (field) => (field.type === 'text'
                    ? (
                      <dl className={styles.line}>
                        <dt className={styles.label}>{`${t(field.label)}:`}</dt>
                        <dd className={styles.value}>
                          {field.value}
                          {/* <CurrencyFormat
                          value={_.get(paymentRequest, 'paidAmount')}
                          currency={currency} /> */}
                        </dd>
                      </dl>
                    )
                    : null))
                }
                {
                !_.isEmpty(_.get(paymentRequest, 'paymentProvider.description'))
                  && (
                  <div
                    className={styles.paymentDescription}
                    dangerouslySetInnerHTML={
                      { __html: _.get(paymentRequests, '[0].paymentProvider.description') }
                    }
                  />
                  )
                }
                {
                  order.state === 'reserved'
                    && (
                    <div
                      className={styles.paymentDescription}
                      dangerouslySetInnerHTML={
                        { __html: t('screens.orderDetail.billing.paymentReservedOfflinePaymentInstructions') }
                      }
                    />
                    )
                }
                {
                  _.map(availableFields || [], (field) => (field.type === 'qrcode'
                    ? (
                      <React.Fragment key={`available-fields-qrcode-${field.code}`}>
                        {/* <div
                          className={styles.paymentDescription}
                          dangerouslySetInnerHTML={
                            { __html: t('screens.orderDetail.billing.paymentQrCodeInstructions') }
                          }
                        /> */}
                        <p className={styles.paymentModal}>
                          <a
                            onClick={() => onOpenQRCode(_.get(paymentRequest, 'paymentData.newebpayOfflineParams')
                              || _.get(paymentRequest, 'paymentData.pay2GoOfflineParams', ''))}
                            className={styles.paymentModalLink}
                          >
                            {t('ui.paymentOrderModal.title')}
                          </a>
                        </p>
                      </React.Fragment>
                    )
                    : null))
                }
              </div>
            )
          })
        }
      </div>
      <Modal
        dialog
        isOpen={isOpenQRCode}
      >
        <div className={styles.dialogContainer}>
          <div className={styles.dialogBody}>
            <h1 className={styles.dialogTitle}>{t('ui.paymentOrderModal.title')}</h1>
            <QRCode
              value={codeQr}
              size={170}
            />
            <p className={styles.memberNumber}>{codeQr}</p>
            <div
              className={styles.dialogDescription}
              dangerouslySetInnerHTML={
                { __html: t('ui.paymentOrderModal.paymentQrCodeInstructions') }
              }
            />
            {/* <p className={styles.dialogDescription}>
            {t('ui.paymentOrderModal.paymentQrCodeInstructions')}
            </p> */}
          </div>
          <div className={styles.dialogButtons}>
            <Button
              className={styles.button}
              dark
              text={t('ui.paymentOrderModal.buttons.close')}
              onClick={() => onOpenQRCode(undefined)}
            />
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default AccountOrderPaymentsView
