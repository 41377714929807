/* eslint-disable max-len */
import _ from 'lodash'
import React from 'react'
import useStyles from './account-order-product-table-style'
import AccountOrderProductRow from '../account-order-product-row'
import combineClassNames from '../../../../helpers/combineClassNames'

// const AccountOrderProductTableView = ({ t, orderLines }) => {
const AccountOrderProductTableView = ({
  t, orderLines, currency, returnRequestOrderLine,
}) => {
  const styles = useStyles()
  // hide whole column if all items hide variant
  const groupedLineProperties = _.map(
    _.filter(orderLines, 'isMainProperty'),
    (line) => ({
      ...line,
      addons: _.filter(
        orderLines,
        ({ isMainProperty, groupUuid }) => !isMainProperty && _.isEqual(groupUuid, _.get(line, 'groupUuid')),
      ),
    }),
  )
  const hideColorOptionColumn = _.every(groupedLineProperties, ['sku.product.colorOptionVariantType.hideVariant', true])
  const hideSizeOptionColumn = _.every(groupedLineProperties, ['sku.product.sizeOptionVariantType.hideVariant', true])
  return (
    <div>
      <section className={styles.listProducts}>
        <header>
          <div className={combineClassNames([styles.col, styles.thumbnail])}> </div>
          <div className={combineClassNames([styles.col, styles.name])}> </div>
          {
            !hideSizeOptionColumn && (
              <div className={styles.col}>{t('screens.orderDetail.detailsProduct.size')}</div>
            )
          }
          {
            !hideColorOptionColumn && (
              <div className={styles.col}>{t('screens.orderDetail.detailsProduct.color')}</div>
            )
          }
          <div className={styles.col}>{t('screens.orderDetail.detailsProduct.price')}</div>
          <div className={styles.col}>{t('screens.orderDetail.detailsProduct.discount')}</div>

          <div className={styles.col}>{t('screens.orderDetail.detailsProduct.qty')}</div>
          <div className={styles.col}>{t('screens.orderDetail.detailsProduct.subtotal')}</div>
        </header>
        {groupedLineProperties.map((orderLine) => (
          <AccountOrderProductRow
            key={orderLine.id}
            t={t}
            orderLine={orderLine}
            currency={currency}
            hideColorOptionColumn={hideColorOptionColumn}
            hideSizeOptionColumn={hideSizeOptionColumn}
            returnRequestOrderLine={returnRequestOrderLine}
          />
        ))}
      </section>
    </div>
  )
}

export default AccountOrderProductTableView
