/* eslint-disable no-unused-vars */
import _ from 'lodash'
import React, { useState, forwardRef, useImperativeHandle } from 'react'
import { useTranslation } from 'react-i18next'
import useStyles from './account-order-style'
import {
  Layout,
  Loading,
  Button,
  OrderSummary,
  SEO,
  Modal,
} from '../../../ui'
// import Modal from '../../../../ui/modal/modal-view';
import AccountOrderDetail from './account-order-details'
import AccountOrderDelivery from './account-order-delivery'
import AccountOrderMetaTwInvoice from './account-order-meta-tw-invoice'
import AccountOrderPayments from './account-order-payments'
import ArrowLeft from '../../../assets/icons/icon_left_arrow.svg'

const AccountOrderView = forwardRef((props, ref) => {
  const {
    currency,
    loading,
    metaDefinitions,
    order,
    seoTitle,
    user,
    taiwanInvoiceEnabled,
    onBackToOrders,
    onCancelOrder,
    isCancelOrderAvailable,
  } = props
  const styles = useStyles()
  const { t } = useTranslation()
  const canCancelOrder = isCancelOrderAvailable && _.get(order, 'canCancelOrder')

  const [
    isCancelOrderConfirmationDialogOpen,
    setIsCancelOrderConfirmationDialogOpen,
  ] = useState(false)

  useImperativeHandle(ref, () => ({
    closeModalFallback() {
      setIsCancelOrderConfirmationDialogOpen(false)
    },
  }));

  const onRequestClose = () => {
    setIsCancelOrderConfirmationDialogOpen(false)
  }
  const handleCancelOrderButtonClick = () => {
    setIsCancelOrderConfirmationDialogOpen(true)
  }

  const returnRequestOrderLine = []
  _.forEach(_.get(order, 'returnRequests'), (returnRequest) => {
    _.forEach(_.get(returnRequest, 'returnRequestLines'), (returnRequestLine) => {
      if (_.get(returnRequestLine, 'orderLineId')) {
        returnRequestOrderLine.push({
          id: _.get(returnRequestLine, 'orderLineId'),
          state: _.get(returnRequest, 'state'),
        })
      }
    })
  })

  return (
    <Layout login user={user}>
      <SEO title={seoTitle} />
      { loading && <Loading />}
      {
        !_.isEmpty(order) && (
          <div className={styles.containerFluid}>
            <div className={styles.container}>
              <Button
                iconImage={ArrowLeft}
                hasIconleft
                className={styles.buttonBack}
                text={t('screens.orderDetail.buttons.back')}
                onClick={onBackToOrders}
              />
              <AccountOrderDetail t={t} order={order} />
              {
                _.map(order.shipments, (shipment, shipmentIndex) => (
                  <AccountOrderDelivery
                    key={`shipment_${shipmentIndex}`}
                    t={t}
                    shipment={shipment}
                    cartMeta={_.get(order, 'cartMeta')}
                    currency={currency}
                    returnRequestOrderLine={returnRequestOrderLine}
                  />
                ))
              }
              <div className={styles.orderAdditionalInfo}>
                <AccountOrderPayments
                  t={t}
                  currency={currency}
                  order={order}
                />
                {
                  taiwanInvoiceEnabled
                    ? (
                      <AccountOrderMetaTwInvoice
                        t={t}
                        currency={currency}
                        metaDefinitions={metaDefinitions}
                        order={order}
                      />
                    )
                    : null
                }
              </div>
              <div className={styles.orderSummaryContainer}>
                <h3 className={styles.orderSummaryTitle}>{t('screens.orderDetail.summary.title')}</h3>
                <OrderSummary
                  t={t}
                  summary={{
                    quantityCount: _.get(order, 'orderLinesTotalQuantityCount'),
                    deliveryFee: _.get(order, 'priceDetails.deliveryFee')
                                  || _.get(order, 'priceDetails.orderDeliveryFee'),
                    discount: _.get(order, 'priceDetails.discount')
                                  || _.get(order, 'priceDetails.orderDiscount'),
                    discountGroup: _.get(order, 'priceDetails.discountGroup')
                                  || _.get(order, 'priceDetails.orderDiscountGroup'),
                    subtotalPrice: _.get(order, 'priceDetails.subtotalPrice')
                                  || _.get(order, 'priceDetails.orderSubtotalPrice'),
                    totalPrice: _.get(order, 'priceDetails.totalPrice')
                                  || _.get(order, 'priceDetails.orderTotalPrice'),
                  }}
                />
              </div>
              <p className={styles.messageStyle}>{t('screens.account.orders.message')}</p>
              <div className={styles.buttonBox}>
                {
                  canCancelOrder
                    && (
                    <Button
                      alert
                      uppercase
                      text={t('screens.orderDetail.buttons.cancelOrder')}
                      onClick={handleCancelOrderButtonClick}
                    />
                    )
                }
                <Button dark text={t('screens.orderDetail.buttons.back')} onClick={onBackToOrders} />
              </div>
            </div>
          </div>
        )
      }
      <Modal
        dialog
        isOpen={isCancelOrderConfirmationDialogOpen}
        onRequestClose={onRequestClose}
      >
        <div className={styles.dialogContainer}>
          <div className={styles.dialogBody}>
            <h1 className={styles.dialogTitle}>{t('screens.orderDetail.buttons.cancelOrder')}</h1>
            <p className={styles.dialogDescription}>{t('screens.orderDetail.buttons.messageModal')}</p>

          </div>
          <Button
            className={styles.button}
            dark
            uppercase
            text={t('screens.orderDetail.buttons.confirm')}
            onClick={onCancelOrder}
          />
          <Button
            className={styles.button}
            uppercase
            border
            text={t('screens.orderDetail.buttons.cancel')}
            onClick={onRequestClose}
          />
        </div>
      </Modal>
    </Layout>
  )
})

export default AccountOrderView
