import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  container: () => ({
    position: 'relative',
    marginTop: '2rem',
    marginBottom: '2rem',
    padding: '0 2rem 0.1rem',
    textAlign: 'left',
    [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
      display: 'flex',
    },
  }),
  title: {
    textTransform: 'capitalize',
    fontSize: '3rem',
    marginBottom: '2rem',
  },
  invoiceTo: {
    marginBottom: '3rem',
    width: '100%',
    [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
      width: 'calc(50% - 2rem)',
    },
  },
  row: {
    fontSize: '1.4rem',
    lineHeight: 1.4,
    margin: 0,
  },
  label: {
    fontSize: 'inherit',
    fontWeight: 'bold',
    paddingRight: '0.4rem',
  },
  value: {
    display: 'inline-block',
    margin: 0,
  },
}))

export default useStyles
